import React, { useEffect, useRef, useState } from 'react'

import { useDebounce } from 'react-use'

import { useTranslation } from '@/lib/i18n'
import { Box, InputAdornment, TextField } from '@mui/material'

import { ClearButton } from './clear-button'
import { SearchIcon } from './icons'

import { DEFAULT_SEARCH_TERM_LENGTH } from '../const'
import { SEARCH_FIELD_WIDTH } from '../theme'
import { palette } from '../theme/palette'
import { Testable } from '../types/testable'

type SearchFieldProps = Testable & {
    value: string | null
    onChange: (value: string) => void
}

export const SearchField: React.FC<SearchFieldProps> = ({ dataTestId, onChange, value }) => {
    const { t } = useTranslation('common')

    const [localSearchTerm, setLocalSearchTerm] = useState<string>(value || '')
    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus()
        }
    }, [])

    useDebounce(
        () => {
            if ((localSearchTerm === '' && value === null) || localSearchTerm === value) {
                return
            }

            onChange(localSearchTerm)
        },
        500,
        [localSearchTerm],
    )

    return (
        <Box minHeight={64} maxWidth={SEARCH_FIELD_WIDTH}>
            <TextField
                inputRef={inputRef}
                placeholder={t('contracts.search.placeholder')}
                size="small"
                color="secondary"
                sx={{
                    '& *> input': {
                        fontSize: 16,
                    },
                    '& .MuiInputAdornment-positionStart': {
                        marginLeft: -0.5,
                    },
                    '& .MuiOutlinedInput-root':
                        localSearchTerm && localSearchTerm.length < DEFAULT_SEARCH_TERM_LENGTH
                            ? {
                                  '& fieldset': { borderColor: palette.primary.main },
                                  '&:hover fieldset': {
                                      borderColor: palette.primary.main,
                                      boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.4)',
                                  },
                                  '&.Mui-focused fieldset': {
                                      borderColor: palette.primary.main,
                                      borderWidth: '1px',
                                  },
                              }
                            : null,
                }}
                error={Boolean(localSearchTerm) && localSearchTerm.length < DEFAULT_SEARCH_TERM_LENGTH}
                value={localSearchTerm}
                onKeyUp={(event) => event.key === 'Escape' && setLocalSearchTerm('')}
                onChange={(event) => setLocalSearchTerm(event.target.value)}
                helperText={localSearchTerm && localSearchTerm.length < DEFAULT_SEARCH_TERM_LENGTH ? t('searchTermLength') : null}
                slotProps={{
                    input: {
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        endAdornment: localSearchTerm && <ClearButton onClick={() => setLocalSearchTerm('')} />,
                    },
                    htmlInput: {
                        'data-testid': dataTestId,
                    },
                }}
            />
        </Box>
    )
}
