'use client'

import React from 'react'

import { useTranslation } from '@/lib/i18n'
import { LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'

import { BusinessUnitCell, ShopsTableCell } from '../../layout/list-view'
import { TableHeadCell } from '../../table-head-cell'
import { TablePagination } from '../../table-pagination'

import { useLocalizedRouter } from '../../../hooks/use-localized-router'
import { excludeArrayItemsByProperty } from '../../../utils/array/exclude-array-items-by-property'
import { translateDate } from '../../../utils/formatting'

import { LicenceContractStatus, LicenceContractsListQueryHookResult, ShopStatus } from '../../../../lib/generated/graphql'

type LicenceContractsTableProps = {
    data: LicenceContractsListQueryHookResult['data']
    loading?: boolean
    setPagination: (pageSize: number, pageNumber: number) => void
    contractStatus: string
}

export const LicenceContractsTable: React.FC<LicenceContractsTableProps> = ({ data, loading, setPagination, contractStatus }) => {
    const { t } = useTranslation('common')
    const router = useLocalizedRouter()

    const licenceContracts = data?.licenceContracts?.licenceContracts
    const pagination = data?.licenceContracts?.pagination

    const rowData = (licenceContracts || []).map((licenceContract) => {
        const allShops = licenceContract.licenceAreas?.flatMap((licenceArea) => licenceArea.shops || []) || []
        const filteredShops = excludeArrayItemsByProperty(allShops, 'status', [ShopStatus.CLOSED, ShopStatus.DRAFT])

        return {
            ...licenceContract,
            shops: filteredShops,
        }
    })

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeadCell>{t('contracts.list.header.area')}</TableHeadCell>
                        <TableHeadCell>{t('enum.BusinessUnit.title')}</TableHeadCell>
                        <TableHeadCell>{t('contracts.list.header.licensePartner')}</TableHeadCell>
                        <TableHeadCell>{t('contracts.list.header.shops')}</TableHeadCell>
                        <TableHeadCell>
                            {contractStatus === LicenceContractStatus.INACTIVE ? t('endDate') : t('expectedEndDate')}
                        </TableHeadCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ? (
                        <TableRow>
                            <TableCell colSpan={5}>
                                <LinearProgress />
                            </TableCell>
                        </TableRow>
                    ) : null}
                    {rowData.map(({ id, businessUnit, licensee, licenceAreas, actualEndDate, expectedEndDate, shops }) => (
                        <TableRow
                            hover
                            key={id}
                            onClick={() =>
                                router.push({
                                    path: 'licenceContractDetail',
                                    params: { licenceContractId: id },
                                })
                            }>
                            <TableCell>
                                <Typography variant="body3">{licenceAreas?.map((licenceArea) => licenceArea.name).join(', ')}</Typography>
                            </TableCell>
                            <TableCell>
                                <BusinessUnitCell businessUnit={businessUnit} />
                            </TableCell>
                            <TableCell>
                                <Typography variant="body3">{licensee?.name || '-'}</Typography>
                            </TableCell>
                            <TableCell>
                                <ShopsTableCell shops={shops} />
                            </TableCell>
                            <TableCell>
                                <Typography variant="body3">
                                    {actualEndDate ? translateDate(t, actualEndDate) : translateDate(t, expectedEndDate)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                {pagination ? <TablePagination pagination={pagination} setPagination={setPagination} /> : null}
            </Table>
        </TableContainer>
    )
}
