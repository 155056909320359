import React from 'react'

import { AviationIcon, CommercialIcon, ResidentialIcon, YachtingIcon } from './icons'

import { assertUnreachable } from '../utils/assert-unreachable'

import { BusinessUnit } from '../../lib/generated/graphql'

export const BusinessUnitIcon: React.FC<{ businessUnit: BusinessUnit }> = ({ businessUnit }) => {
    switch (businessUnit) {
        case BusinessUnit.AVI:
            return <AviationIcon />
        case BusinessUnit.COM:
            return <CommercialIcon />
        case BusinessUnit.RES:
            return <ResidentialIcon />
        case BusinessUnit.YAC:
            return <YachtingIcon />
        default:
            return assertUnreachable(businessUnit)
    }
}
