import React from 'react'

import { TFunction, useTranslation } from '@/lib/i18n'

import { TextExpandableTableCell } from './text-expandable-table-cell'

import { Shop, ShopStatus } from '../../../../lib/generated/graphql'

type MinimalShop = Pick<Shop, 'id' | 'name' | 'status'>

const shopName = (t: TFunction, shop: MinimalShop) =>
    `${shop.name} ${shop.status !== ShopStatus.OPENED ? ` (${t(`enum.ShopStatus.${shop.status}`)})` : ''}`

export const ShopsTableCell: React.FC<{ shops: MinimalShop[] }> = ({ shops }) => {
    const { t } = useTranslation('common')

    return <TextExpandableTableCell label={t('shop')} pluralLabel={t('shops')} texts={shops.map((shop) => shopName(t, shop))} />
}
