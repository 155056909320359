import { useCallback } from 'react'

import merge from 'lodash.merge'

import { DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE } from '../../const'
import { skipSearchTerm } from '../../utils/skip-search-term'

type BaseQueryConfig = {
    searchTerm: string
    pageSize: number
    pageNumber: number
}

type ReplaceStateFunction<T> = (updateFn: (state: T & BaseQueryConfig) => T & BaseQueryConfig) => void

export const useSetQueryWithPaginationReset = <T extends Record<string, unknown>>(replaceState: ReplaceStateFunction<T>) => {
    const setQueryStateWithPaginationReset = useCallback(
        (newValues: Partial<T & BaseQueryConfig>) => {
            // Skip if the search term is too short
            if (skipSearchTerm(newValues.searchTerm)) {
                return
            }

            return replaceState((values) =>
                merge(values, {
                    pageSize: DEFAULT_PAGE_SIZE,
                    pageNumber: DEFAULT_PAGE_NUMBER,
                    ...newValues,
                }),
            )
        },
        [replaceState],
    )

    return { setQueryStateWithPaginationReset }
}
