import React, { useState } from 'react'

import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { Box, Collapse, IconButton, Stack, Typography } from '@mui/material'

export const TextExpandableTableCell: React.FC<{ label: string; pluralLabel: string; texts: string[] }> = ({
    label,
    pluralLabel,
    texts,
}) => {
    const [expanded, setExpanded] = useState(false)

    return (
        <Stack>
            <Box>
                <Typography variant="body3" fontWeight="fontWeightBold">{`${texts.length} ${
                    texts.length === 1 ? label : pluralLabel
                }`}</Typography>

                {texts.length > 1 && (
                    <IconButton
                        size="small"
                        onClick={(event) => {
                            event.stopPropagation()
                            setExpanded(!expanded)
                        }}>
                        {expanded ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                )}
            </Box>
            {texts.length === 1 && <Typography variant="body3">{texts[0]}</Typography>}
            {texts.length > 1 && (
                <>
                    <Typography variant="body3">
                        {texts[0]}
                        {!expanded && `, +${texts.length - 1}`}
                    </Typography>
                    <Collapse in={expanded}>
                        {texts.slice(1, texts.length).map((text) => (
                            <span key={text}>
                                <Typography variant="body3">{text}</Typography>
                                <br />
                            </span>
                        ))}
                    </Collapse>
                </>
            )}
        </Stack>
    )
}
