import { useTranslation } from '@/lib/i18n'

import { FormButtonProps } from '../form-drawer/form-button'

import { LicenceContractStatus } from '../../../../lib/generated/graphql'

export const useContractFormButtonTitle = (title: string, licenceContractStatus?: LicenceContractStatus) => {
    const { t } = useTranslation('common')

    let buttonTitle: FormButtonProps['buttonTitle'] = title
    let buttonType: FormButtonProps['buttonType'] = 'edit' as FormButtonProps['buttonType']
    let dialogTitle: string = title

    const isInactive = licenceContractStatus !== undefined && licenceContractStatus === LicenceContractStatus.INACTIVE

    if (isInactive) {
        buttonTitle = t('contract.form.inactiveContract')
        buttonType = 'disabled'
        dialogTitle = ''
    }

    return { buttonTitle, buttonType, dialogTitle }
}
