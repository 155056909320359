import React from 'react'

import { CircularProgress } from '@mui/material'
import { styled } from '@mui/material/styles'

import { CloseIcon } from './icons'

const StyledButton = styled('button')(`
    display: flex;
    align-content: center;
    margin-right: -4px;
    background-color: transparent;
    border: 0;
    cursor: pointer;
`)

export const ClearButton: React.FC<{ loading?: boolean } & React.HTMLAttributes<HTMLButtonElement>> = ({ loading, ...restProps }) => (
    <StyledButton {...restProps}>{loading ? <CircularProgress size={20} sx={{ marginRight: 0.25 }} /> : <CloseIcon />}</StyledButton>
)
