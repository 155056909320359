import React from 'react'

import { TablePagination as MuiTablePagination, TableFooter, TableRow } from '@mui/material'

import { DataTestIds } from '../const'

import { Pagination } from '../../lib/generated/graphql'

export const TablePagination: React.FC<{
    pagination: Pick<Pagination, 'pageNumber' | 'pageSize' | 'total'>
    setPagination: (pageSize: number, pageNumber: number) => void
}> = ({ pagination, setPagination }) => (
    <TableFooter>
        <TableRow>
            <MuiTablePagination
                data-testid={DataTestIds.globals.pagination}
                rowsPerPage={10}
                rowsPerPageOptions={[]}
                onPageChange={(_, pageNumber) => {
                    setPagination(pagination.pageSize, pageNumber + 1)
                }}
                count={pagination.total}
                page={pagination.pageNumber - 1}
            />
        </TableRow>
    </TableFooter>
)
