import { factoryParameters, pm, serializers } from 'geschichte'

import { DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE, DEFAULT_SEARCH_TERM_LENGTH } from '../../../const'

import { BusinessUnit, ContractType, LicenceContractStatus } from '../../../../lib/generated/graphql'

const config = {
    businessUnit: pm('businessUnit', serializers.string),
    contractType: pm('contractType', serializers.string),
    pageSize: pm('pageSize', serializers.int),
    pageNumber: pm('pageNumber', serializers.int),
    searchTerm: pm('searchTerm', serializers.string),
    status: pm('status', serializers.string),
}

const defaultInitialValues = {
    businessUnit: '',
    contractType: '',
    pageSize: DEFAULT_PAGE_SIZE,
    pageNumber: DEFAULT_PAGE_NUMBER,
    searchTerm: '',
    status: LicenceContractStatus.ACTIVE,
}

const { useQuery } = factoryParameters(config, defaultInitialValues)

export const useLicenceContractQuery = () => {
    const {
        values: { searchTerm, businessUnit, contractType, status, pageSize, pageNumber },
        ...useQueryProps
    } = useQuery()

    const values = {
        businessUnit: (businessUnit || undefined) as unknown as BusinessUnit,
        contractType: (contractType || undefined) as unknown as ContractType,
        pagination: {
            pageSize,
            pageNumber,
        },
        searchTerm: searchTerm?.length >= DEFAULT_SEARCH_TERM_LENGTH ? searchTerm : null,
        status: (status || LicenceContractStatus.ACTIVE) as unknown as LicenceContractStatus,
    }

    return { values, ...useQueryProps }
}
