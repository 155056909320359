import React from 'react'

import { Stack } from '@mui/material'
import { useTheme } from '@mui/material/styles'

type ListViewLayoutProps = {
    children: React.ReactNode
}

export const ListViewLayout: React.FC<ListViewLayoutProps> = ({ children }) => {
    const theme = useTheme()

    return (
        <Stack
            spacing={4}
            padding={theme.spacing(3)}
            sx={{
                '& > :last-child': {
                    marginTop: 1,
                },
            }}>
            {children}
        </Stack>
    )
}
