import React from 'react'

import { useTranslation } from '@/lib/i18n'
import { Stack, Typography } from '@mui/material'

import { BusinessUnitIcon } from '../../business-unit-icon'

import { BusinessUnit } from '../../../../lib/generated/graphql'

export const BusinessUnitCell: React.FC<{ businessUnit?: BusinessUnit | null }> = ({ businessUnit }) => {
    const { t } = useTranslation('common')

    if (!businessUnit) {
        return <>{t('N/A')}</>
    }

    return (
        <Stack direction="row" spacing={1} alignItems="center">
            {<BusinessUnitIcon businessUnit={businessUnit} />}
            <Typography variant="body3">{t(`enum.BusinessUnit.${businessUnit}`)}</Typography>
        </Stack>
    )
}
