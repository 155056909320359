import { Box, Divider, Tab, Tabs } from '@mui/material'

type LMTTabOptions<T> = {
    label: string
    value: T
}

type LMTTabsProps<T> = {
    value: T
    onChange: (value: T) => void
    options: LMTTabOptions<T>[]
}

export const LmtTabs = <T extends unknown>({ value, onChange, options }: LMTTabsProps<T>) => (
    <Box>
        <Tabs value={value} onChange={(_, newValue) => onChange(newValue)}>
            {options.map((option) => (
                <Tab key={option.label} label={option.label} disableRipple value={option.value} />
            ))}
        </Tabs>
        <Divider sx={{ width: '100%' }} />
    </Box>
)
