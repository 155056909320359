'use client'

import React from 'react'

import { WithUserRight } from '@/lib/auth/with-user-right'
import { useTranslation } from '@/lib/i18n'
import { Grid2, Stack, Typography } from '@mui/material'

import { LicenceContractsTable } from './licence-contracts-table'
import { useLicenceContractQuery } from './query-params'

import { useSetQueryWithPaginationReset } from '../use-set-query-with-pagination-reset'

import { LicenceContractFormButton } from '../../forms/licence-contract/licence-contract-form'
import { ListViewLayout } from '../../layout/list-view-layout'
import { LmtTabs } from '../../lmt-tabs'
import { SearchField } from '../../search-field'
import { SelectFilter } from '../../select-filter'

import { DataTestIds, Right } from '../../../const'
import { sortedBusinessUnits } from '../../../const/enum/sorted-business-unit'
import { skipSearchTerm } from '../../../utils/skip-search-term'

import { ContractType, LicenceContractStatus, useLicenceContractsListQuery } from '../../../../lib/generated/graphql'

export const LicenceContractsView: React.FC = () => {
    const { t } = useTranslation('common')
    const { values, replaceState } = useLicenceContractQuery()
    const { setQueryStateWithPaginationReset } = useSetQueryWithPaginationReset(replaceState)

    const { status, businessUnit, searchTerm, contractType } = values

    const { data, loading } = useLicenceContractsListQuery({
        variables: values,
        skip: skipSearchTerm(searchTerm),
    })

    const setPagination = (pageSize: number, pageNumber: number) =>
        replaceState((values) => {
            values.pageSize = pageSize
            values.pageNumber = pageNumber
        })

    return (
        <ListViewLayout>
            <Stack direction="row" justifyContent="space-between">
                <Typography variant="h1" fontWeight="fontWeightBold" color="dark.simple">
                    {t('contracts')}
                </Typography>

                <WithUserRight right={Right.LICENCE_CONTRACT_DRAFT_C}>
                    <LicenceContractFormButton />
                </WithUserRight>
            </Stack>

            <LmtTabs
                value={status}
                onChange={(value) => setQueryStateWithPaginationReset({ status: value })}
                options={Object.values(LicenceContractStatus).map((licenceContractStatus) => ({
                    label: t(`enum.LicenceContractStatus.${licenceContractStatus}`),
                    value: licenceContractStatus,
                }))}
            />

            <Grid2 container justifyContent="space-between" alignItems="baseline">
                <Grid2 size={4}>
                    <SearchField
                        dataTestId={DataTestIds.globals.search}
                        value={searchTerm}
                        onChange={(value) => setQueryStateWithPaginationReset({ searchTerm: value })}
                    />
                </Grid2>
                <Grid2 display="flex" gap={5}>
                    <SelectFilter
                        dataTestId={DataTestIds.licenceContracts.filters.businessUnit}
                        value={businessUnit}
                        onChange={(value) => setQueryStateWithPaginationReset({ businessUnit: value })}
                        selectTitle={t('contracts.list.filter.businessUnit')}
                        selectOptions={[
                            {
                                label: t('all'),
                                value: '',
                            },
                            ...Object.values(sortedBusinessUnits).map((businessUnit) => ({
                                label: t(`enum.BusinessUnit.${businessUnit}`),
                                value: businessUnit,
                            })),
                        ]}
                        sx={{
                            minWidth: 110,
                        }}
                    />
                    <SelectFilter
                        dataTestId={DataTestIds.licenceContracts.filters.contractType}
                        value={contractType}
                        onChange={(value) => setQueryStateWithPaginationReset({ contractType: value })}
                        selectTitle={t('contractType')}
                        selectOptions={[
                            {
                                label: t('all'),
                                value: '',
                            },
                            ...Object.values(ContractType).map((contractType) => ({
                                label: t(`enum.ContractType.${contractType}`),
                                value: contractType,
                            })),
                        ]}
                        sx={{
                            minWidth: 110,
                        }}
                    />
                </Grid2>
            </Grid2>

            <LicenceContractsTable data={data} loading={loading} setPagination={setPagination} contractStatus={status} />
        </ListViewLayout>
    )
}
